<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      Ecommerce: "Loja Virtual",
      Orders: "Pedidos",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",
    },
    es: {
      Ecommerce: "Loja Virtual",
      Orders: "Pedidos",
      "Payment Method": "Forma de Pag",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      alert: {
        order: { type: '', message: '' },
        deliver: { type: '', message: '' },
      },

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",

        items: null,
        status: null,

        approve: 'done',
        deliver: 'done',
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("franchise/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;
            }
          })
          .catch((error) => {
            if (error) {
              this.$route.push("/franchise/orders");
            }
          });
      }
    },
    deliverOrder() {
      api
        .post("franchise/orders/deliver", {
          id: this.order.id
        })
        .then((response) => {
          if (response.data.status == "success") {
            this.alert.order.type = "alert-success";
            this.alert.order.message = response.data.message;

            this.getOrder();
            this.$bvModal.hide('deliverOrder')
          } else {
            this.alert.deliver.type = "alert-danger";
            this.alert.deliver.message = response.data.message;
          }
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Loja Virtual</li>
      <li class="breadcrumb-item"><router-link tag="a" to="/franchise/orders">Pedidos</router-link></li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Pedido</h3>
        <p></p>
      </div>
    </div>

    <div v-if="!order.items" class="text-center">
      <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
    </div>

    <div v-else class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">#{{ order.id }}</h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div v-if="order.user" class="user d-flex mb-4">
                <div class="align-self-center mr-3">
                  <img v-if="order.user.avatar" class="rounded-circle header-profile-user" :src="order.user.avatar" alt="" style="width: 60px; height: 60px;" />
                  <img v-else class="rounded-circle header-profile-user" :src="'https://ui-avatars.com/api/?background=000001&color=fff&name=' +
                    order.user.name
                    " alt="" style="width: 60px; height: 60px;" />
                </div>
                <h5 class="mb-0 flex-fill align-self-center font-size-16">
                  <small>{{ order.user.username }}</small><br />
                  {{ order.user.name }}<br>
                  {{ order.user.cellphone }}
                </h5>
              </div>
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0"></th>
                      <th class="border-0 text-uppercase">{{ t("Item") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Price") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Qty") }}</th>
                      <th class="border-0 text-uppercase text-right">
                        {{ t("Total") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td class="p-0" style="width: 1%">
                        <img v-if="td.image" style="width: 50px; height: 50px; object-fit: cover" class="" :src="td.image" />
                      </td>
                      <td class="align-middle">{{ td.name }}</td>
                      <td class="align-middle">{{ td.price | currency }}</td>
                      <td class="align-middle">{{ td.quantity }}</td>
                      <td class="align-middle text-right">
                        {{ td.subtotal | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-2">{{ t("Status") }}</h3>
                  <div v-for="(td, index) in order.status" :key="index">
                    {{ td.date }} — {{ t(td.status) }}
                  </div>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">
                    {{ t("Detalhes do pagamento") }}
                  </h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t("DISCOUNT") }}</td>
                          <td class="px-0 py-1 text-right">
                            - {{ order.discount | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t("SUBTOTAL") }}</td>
                          <td class="px-0 py-1 text-right">
                            {{ order.subtotal | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">
                            {{ t("TOTAL") }}
                          </td>
                          <td class="px-0 py-1 font-weight-bold text-right">
                            {{ order.total | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div v-if="order.approve == 'done' && order.deliver == 'pending'" class="my-3 text-center">
                <button class="btn btn-default btn-lg" @click="$bvModal.show('deliverOrder')">
                  Entregar Pedido
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="deliverOrder" hide-footer centered>
      <template v-slot:modal-title>Pedido no. {{ order.id }}</template>
      <div v-if="alert.deliver.message" :class="'alert ' + alert.deliver.type">{{ t(alert.deliver.message) }}</div>
      <b-form class="p-3" @submit.prevent="deliverOrder">
        <div v-if="order.user" class="d-block text-center">
          <h5>
            Desejá realmente entregar o<br />
            pedido no. <strong>{{ order.id }}</strong> no valor de
            <strong>{{ order.total | currency }}</strong><br />
            de <strong>{{ order.user.name }}</strong>?
          </h5>
        </div>
        <div class="d-flex flex-row pt-3">
          <b-button class="m-3" variant="default" type="submit" block>Sim</b-button>
          <b-button class="m-3" variant="danger" block @click="$bvModal.hide('deliverOrder')">Não</b-button>
        </div>
      </b-form>
    </b-modal>
  </Layout>
</template>
